import React, { useEffect, useState } from "react"
import {
  FlexBox,
  DescriptionBoxHolder,
  ContainerDesk,
  ImageGroup1,
  DescriptionBox,
  Title,
  Description,
  ExampleImg,
} from "../../../ToolsStyles/HowItWorksStyeled"
import Step1 from "../../Assets/HowToImage_Step_1_delete.png"
import Step2 from "../../Assets/HowToImage_Step_2_delete.png"
import Step3 from "../../Assets/HowToImage_Step_3_delete.png"
import Step4 from "../../Assets/HowToImage_Step_2_delete.png"
import Group1 from "../../../merge/MergeAssets/Group1.svg"
import Group2 from "../../../merge/MergeAssets/Group2.svg"
import Group3 from "../../../merge/MergeAssets/Group3.svg"
import Group4 from "../../../merge/MergeAssets/Group4.svg"
import ActiveGroupOne from "../../../merge/MergeAssets/GroupActiveOne.svg"
import ActiveGroupTwo from "../../../merge/MergeAssets/GroupActiveTwo.svg"
import GroupActiveThree from "../../../merge/MergeAssets/GroupActiveThree.svg"
import GrouActiveFour from "../../../merge/MergeAssets/GroupActiveFour.svg"
import HowItWorksMobile from "./HowItWorksMobile"

const HowItWorks = () => {
  const [IsActive, setIsActive] = useState(1)

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (!window.clicked) {
  //       setIsActive(prevActive => {
  //         const newActive = prevActive === 3 ? 1 : prevActive + 1
  //         return newActive
  //       })
  //     } else {
  //       window.clicked = false
  //     }
  //   }, 5000)

  //   return () => {
  //     clearInterval(intervalId)
  //   }
  // }, [])

  const ActiveTab = id => {
    setIsActive(id)

    window.clicked = true
  }

  return (
    <div>
      <ContainerDesk>
        <FlexBox>
          <DescriptionBoxHolder>
            <DescriptionBox
              onClick={() => ActiveTab(1)}
              ActiveState={1 == IsActive}
            >
              {IsActive === 1 ? (
                <ImageGroup1 src={ActiveGroupOne} />
              ) : (
                <ImageGroup1 src={Group1} />
              )}

              <Title ActiveState={IsActive === 1}>Upload document</Title>
              <Description>
                Upload the PDF that you wish to delete pages from.{" "}
              </Description>
            </DescriptionBox>
            <DescriptionBox
              onClick={() => ActiveTab(2)}
              ActiveState={2 == IsActive}
            >
              {IsActive === 2 ? (
                <ImageGroup1 src={ActiveGroupTwo} />
              ) : (
                <ImageGroup1 src={Group2} />
              )}
              <Title ActiveState={IsActive === 2}>
                Select page(s) to delete
              </Title>
              <Description>
                Specify the page or pages that you wish to delete or remove from
                your PDF file.
              </Description>
            </DescriptionBox>

            <DescriptionBox
              onClick={() => ActiveTab(3)}
              ActiveState={3 == IsActive}
            >
              {IsActive === 3 ? (
                <ImageGroup1 src={GroupActiveThree} />
              ) : (
                <ImageGroup1 src={Group3} />
              )}
              <Title ActiveState={IsActive === 3}>Press Delete</Title>
              <Description>Press the Delete button.</Description>
            </DescriptionBox>

            <DescriptionBox
              onClick={() => ActiveTab(4)}
              ActiveState={4 == IsActive}
            >
              {IsActive === 4 ? (
                <ImageGroup1 src={GrouActiveFour} />
              ) : (
                <ImageGroup1 src={Group4} />
              )}
              <Title ActiveState={IsActive === 4}>Download</Title>
              <Description>Download your PDF</Description>
            </DescriptionBox>
          </DescriptionBoxHolder>

          {IsActive === 1 ? (
            <ExampleImg
              onClick={() => {
                window.setTimeout(() => window.scrollTo(window.scrollX, 0), 1)
              }}
              src={Step1}
              alt="example of how to upload file"
            />
          ) : null}
          {IsActive === 2 ? (
            <ExampleImg
              onClick={() =>
                window.setTimeout(() => window.scrollTo(window.scrollX, 0), 1)
              }
              src={Step2}
              alt="example of how to delete file"
            />
          ) : null}
          {IsActive === 3 ? (
            <ExampleImg
              onClick={() =>
                window.setTimeout(() => window.scrollTo(window.scrollX, 0), 1)
              }
              src={Step3}
              alt="example of how to download file"
            />
          ) : null}
          {IsActive === 4 ? (
            <ExampleImg
              onClick={() =>
                window.setTimeout(() => window.scrollTo(window.scrollX, 0), 1)
              }
              src={Step4}
              alt="example of how to download file"
            />
          ) : null}
        </FlexBox>
      </ContainerDesk>
      <HowItWorksMobile />
    </div>
  )
}

export default HowItWorks
