import React from "react"
import ReactDOMServer from "react-dom/server"

import PdfdisplayDelete from "../delete/PdfdisplayDelete"
export default function displayPdfSyncDelete(
  pdfData,
  fileData,
  appendTo,
  order,
  setFileRendered,
  removePassProtected,
  setErrorFiles,
  ErrorPopup,
  setPageNum
) {
  return new Promise(async (resolve, reject) => {
    let protectedPass = false
    const pdf = await window.pdfjsLib
      .getDocument({
        data: pdfData,
      })
      .promise.catch(() => {
        console.log("Password protected: ", fileData.name)
        removePassProtected(fileData.name)
        setErrorFiles(erF => [...erF, fileData.name])

        protectedPass = true
      })

    if (protectedPass) {
      setFileRendered(order)

      return resolve(false)
    }

    const pages = pdf.numPages

    if (pages > 100) {
      setErrorFiles(erF => [...erF, fileData.name])
      ErrorPopup("This file has more than 100 pages.")
      setFileRendered(order)

      return resolve(false)
    }

    if (pages == 1) {
      setErrorFiles(erF => [...erF, fileData.name])
      ErrorPopup("Uploaded file need to be with multiple pages")
      setFileRendered(1)

      return resolve(false)
    }

    window.pages = pages
    setPageNum(pages)

    for (let i = 0; i < pages; i++) {
      const page = await pdf.getPage(i + 1)
      const viewport = page.getViewport({ scale: pages == 1 ? 0.585 : 0.337 })

      // Get the raw data for the page
      const operatorList = await page.getOperatorList()

      // Calculate the size of the page in kilobytes
      const pageSizeInKB = JSON.stringify(operatorList).length

      console.log("page size:", pageSizeInKB)

      const canvas = document.createElement("canvas")

      const context = canvas.getContext("2d")
      canvas.height = viewport.height
      canvas.width = viewport.width
      canvas.style.marginDown = "64px"

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      }

      await page.render(renderContext).promise

      var dataURL = canvas.toDataURL()

      let str = JSON.stringify({ name: fileData.name, size: fileData.size })
      setFileRendered((i + 1) / pages)

      document.getElementById(
        appendTo
      ).innerHTML += ReactDOMServer?.renderToString(
        <PdfdisplayDelete
          element={dataURL}
          name={i + 1}
          file={str}
          order={i + 1}
          oneDisplay={pages == 1}
          pageSize={pageSizeInKB}
        >
          {" "}
          test{" "}
        </PdfdisplayDelete>
      )
    }

    setFileRendered(order)

    resolve(true)
  })
}
