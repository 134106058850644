import client from "../toolApi"
import { DataLayer } from "../../helpers/GA"
import { set } from "lodash"
export const DeleteFiles = (
  data,
  setDownload,
  setUploadPct,
  setProcessPct,
  setDownloadPct
) =>
  client
    .post("/uploadNew", data.formData, {
      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setUploadPct(percentCompleted)
      },
    })
    .then(res => {
      window.fileName = res.data.message[0]
      DataLayer("Online tool", "Upload", "Delete")

      client
        .get(`/deleteTool?pages=${data.pages}&fileName=${window.fileName}`) //data.fileName
        .then(res => {
          DataLayer("Online tool", "Delete pages", "Delete")
          setProcessPct(100)

          client
            .get(`/downloadDelete?fileName=${window.fileName}`, {
              responseType: "blob",
              onDownloadProgress: progressEvent => {
                var percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )
                setDownloadPct(percentCompleted)
              },
            })
            .then(res => {
              const link = document.createElement("a")
              setDownload(link)
              link.href = URL.createObjectURL(new Blob([res.data]))
              link.download = data.fileName.replace(".pdf", "") + "_deleted.pdf"
              document.body.appendChild(link)
              link.click()
              DataLayer("Online tool", "Download", "Delete")
            })
            .catch(() => {
              DataLayer("Online tool", "Error download", "Delete")
            })
        })
        .catch(() => {
          DataLayer("Online tool", "Error delete pages", "Delete")
        })
    })
    .catch(() => {
      DataLayer("Online tool", "Error upload", "Delete")
    })
