import React from "react"
import styled from "styled-components"

const Trial = styled.div`
  margin-top: auto;
  display: flex;
  margin-bottom: 32px;
  margin-top: 32px;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  a {
    color: #6361ff;
    text-decoration: none;
  }

  .desktop {
    display: flex;
    @media (max-width: 620px) {
      display: none;
    }
  }

  .mobile {
    display: flex;
    @media (min-width: 619px) {
      display: none;
    }
    white-space: nowrap;
  }
`

const CtaTrial = styled.div`
  color: #6361ff;
  text-decoration: none;
  display: inline;
  cursor: pointer;
  white-space: nowrap;

  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 8px;
  }
`

function TrialMessage({ openPopup }) {
  return (
    <Trial>
      <div className="desktop">
        Need it faster? Try PDF Pro's desktop app for free,
        <CtaTrial onClick={openPopup}>&nbsp;right now →</CtaTrial>
      </div>
      <div className="mobile">
        Try desktop app for free,
        <CtaTrial onClick={openPopup}>&nbsp;right now →</CtaTrial>
      </div>
    </Trial>
  )
}

export default TrialMessage
