import React from "react"
import {
  BlogCard,
  Container,
  BlogImage,
  BlogDescription,
  BlogTitle,
  BlogReadTime,
} from "../../../ToolsStyles/BlogSectionStyled"

import BlogImage1 from "../../../ToolBlogImages/Extract-Text-Blog-image.png"
import BlogImage2 from "../../Assets/BlogImage2.png"
import BlogImage3 from "../../Assets/Combiner.png"
import BlogImage4 from "../../Assets/AddPage.avif"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { navigate } from "gatsby"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1174 },
    items: 4,
  },
  laptop: {
    breakpoint: { max: 1174, min: 900 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 400 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
  },
}
const BlogSection = () => {
  return (
    <Container>
      <Carousel responsive={responsive}>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-delete-pages-from-pdf/")}
        >
          <BlogImage src={BlogImage2} alt="blog-image" />
          <BlogTitle>How to Delete Pages from PDF on Desktop</BlogTitle>
          <BlogDescription>
            A comprehensive guide to deleting PDF pages on desktop computer.
            Free and paid methods included, so you can do more with PDF.
          </BlogDescription>
          <BlogReadTime>3 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() =>
            navigate("/blog/guides/how-to-extract-pages-from-pdf/")
          }
        >
          <BlogImage src={BlogImage1} alt="blog-image" />
          <BlogTitle>How to Extract Pages from PDF (2023)</BlogTitle>
          <BlogDescription>
            Have you ever needed to extract specific pages from a PDF document?
            This guide has everything you need to extract PDF pages like a pro.
          </BlogDescription>
          <BlogReadTime>7 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-combine-pdf-files/")}
        >
          <BlogImage src={BlogImage3} alt="blog-image" />
          <BlogTitle>
            How to Combine PDF Files in 2023 (Free & Online)
          </BlogTitle>
          <BlogDescription>
            Learn everything you need to combine PDF files on Windows, Mac — Any
            device! Free and paid tools, online & desktop.
          </BlogDescription>
          <BlogReadTime>5 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-add-a-page-to-a-pdf/")}
        >
          <BlogImage src={BlogImage4} alt="blog-image" />
          <BlogTitle>How to Add a Page to a PDF</BlogTitle>
          <BlogDescription>
            Adding a page to a PDF is easy with the right tools. We'll show you
            how to add pages from PDFs, Word, Excel, PowerPoint, scanned
            documents, and even images.
          </BlogDescription>
          <BlogReadTime>3 min read</BlogReadTime>
        </BlogCard>
      </Carousel>
    </Container>
  )
}

export default BlogSection
