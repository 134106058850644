import React from "react"
import {
  Container,
  Title,
  Description,
  DescriptionBox,
  SvgHolder,
} from "../../../ToolsStyles/ShortDescriptionStyled"

import Desc1 from "../../../../../icons/tools/delete/DeletIcon1.svg"
import Desc2 from "../../../../../icons/tools/delete/DeletNewIcon.svg"
import Desc3 from "../../../../../icons/tools/lp/merge/DescIcon3.svg"
import Desc4 from "../../../../../icons/tools/lp/merge/DescIcon4.svg"
import Desc5 from "../../../../../icons/tools/lp/merge/DescIcon5.svg"
import Desc6 from "../../../../../icons/tools/lp/merge/DescIcon6.svg"

const ShortDescriptions = () => {
  return (
    <Container>
      <DescriptionBox>
        <SvgHolder>
          <Desc1 />
        </SvgHolder>
        <Title>Permanently Delete PDF Pages</Title>
        <Description>
          Instantly and permanently delete PDF pages from your document.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc2 />
        </SvgHolder>
        <Title>What Can I Delete?</Title>
        <Description>
          You can delete single pages or more than one page from your PDF, in
          just a few clicks.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc3 />
        </SvgHolder>
        <Title>Safe & Secure — Your Privacy Matters to Us</Title>
        <Description>Uploaded files are deleted after 24 hours.</Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc4 />
        </SvgHolder>
        <Title>100% Free to Use</Title>
        <Description>
          Our Delete PDF Pages tool has no limits on the number of pages you can
          delete or the number of documents you can work on. Delete pages from
          PDF to your heart's content.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc5 />
        </SvgHolder>
        <Title>No installation or sign-up required</Title>
        <Description>
          No downloading software or signing up necessary. Simply visit this
          webpage from your browser, and get to work.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc6 />
        </SvgHolder>
        <Title>Works on Any Device</Title>
        <Description>
          This online tool works from within your browser, so you can delete PDF
          pages from any device or system, not just your computer.
        </Description>
      </DescriptionBox>
    </Container>
  )
}

export default ShortDescriptions
