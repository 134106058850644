import React from "react"
import styled from "styled-components"

const BarBack = styled.div`
  width: 320px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
`

const BarButton = styled.div`
  ${props => `width: ${320 * props.percent}px;`}
  background: #6361FF;
  height: 8px;

  border-radius: 4px;
`
const BarBackUpload = styled(BarBack)`
  width: 320px;
  @media (max-width: 864px) {
    width: 240px;
  }
  @media (max-width: 480px) {
    width: 120px;
  }

  border-radius: 4px;
`

const BarButtonUpload = styled(BarButton)`
  ${props => `width: ${320 * props.percent}px;`}
  @media(max-width: 864px) {
    ${props => `width: ${240 * props.percent}px;`}
  }
  @media (max-width: 480px) {
    ${props => `width: ${120 * props.percent}px;`}
  }

  border-radius: 4px;
`

const BarHolder = styled.div`
  display: flex;
  gap: 2px;
  margin-top: 32px;
  @media (max-width: 480px) {
    margin-top: 24px;
  }
`

const BarSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BarText = styled.div`
  @keyframes gradientChange {
    0% {
      background-position: -100%;
    }
    100% {
      background-position: 100%;
    }
  }

  ${props => props.factor === 0 && "color: #d9d9d9;"}
  ${props =>
    props.factor > 0 &&
    props.factor < 1 &&
    `     background: linear-gradient(90deg, #6361FF, #8685FF, #A8A7FF);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  animation: gradientChange 1s linear infinite;`}
${props => props.factor === 1 && "color: #6361FF;"}

@media (max-width: 480px) {
    font-size: 13px;
  }
  margin-bottom: 8px;
`

function ToolLoader({
  uploadPct,
  processPct,
  downloadPct,
  processMessage,
  finishedMessage,
}) {
  return (
    <BarHolder>
      <BarSection>
        <BarText factor={(uploadPct + processPct + downloadPct) / 300}>
          {(uploadPct + processPct + downloadPct) / 300 < 1
            ? `${processMessage} ${(
                ((uploadPct + processPct + downloadPct) / 300) *
                100
              ).toFixed(2)}%`
            : finishedMessage}
        </BarText>
        <BarBackUpload>
          <BarButtonUpload
            percent={(uploadPct + processPct + downloadPct) / 300}
          />
        </BarBackUpload>
      </BarSection>
    </BarHolder>
  )
}

export default ToolLoader
